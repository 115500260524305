.app-legend {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    pointer-events: all;

    &__tabs {
        display: flex;
        justify-content: flex-end;
        white-space: nowrap;
    }
    &__tab {
        width: 2rem;
        height: 2rem;
        margin-left: 0.25rem;

        background: #fff;
        border-radius: 0.25rem;
        box-shadow: 0 8px 16px 0 rgba(0, 56, 76, 0.16);

        &--active {
            height: 2.25rem;
            border-radius: 4px 4px 0 0;
            box-shadow: none;
        }
        &:focus {
            outline: none;
        }
        svg {
            width: 1.5rem;
            height: auto;
        }
    }

    &__content {
        box-shadow: 0 -0.3125rem 1.25rem 0 rgba(0, 0, 0, 0.1);
        background: white;
        display: flex;
        flex-direction: column;
        padding: 1rem;
        border-radius: 0.5rem 0 0.5rem 0.5rem;
    }

    &__group-label {
        font-weight: 500;
        margin-bottom: .5rem;
    }

    &__group-items {
        @apply grid gap-y-2 grid-flow-col grid-cols-2;
        grid-template-rows: repeat(var(--rowcount, 1), minmax(0, 1fr));
    }
}
.app-legend__checkbox {
    min-width: 100px;
    white-space: nowrap;

    input[type='checkbox'] {
        --color: #000;
        --background: #fff;

        -webkit-appearance: none;
        -moz-appearance: none;
        outline: none;

        position: relative;
        height: 1.25rem;
        width: 1.25rem;
        border-radius: 3px;
        margin: 0;

        display: inline-block;
        vertical-align: top;
        cursor: pointer;

        border: 1px solid var(--bc, var(--color));
        background: var(--b, var(--background));
        background-position: -1px -1px;
        transition: background .3s, border-color .3s, box-shadow .2s;

        &:checked {
            --b: var(--color);
            --bc: var(--color);
        }

        &:disabled {
            --b: var(--disabled);
            cursor: initial;
            opacity: .9;


            & + label {
                cursor: initial;
            }
        }

        &:focus {
            box-shadow: 0 0 0 2px var(--color);
        }

        &:after {
            opacity: var(--o, 0);
        }

        &:checked {
            --o: 1;
        }

        &:checked + label[class="app-legend__label parent"] svg {
            --checkbox-scale: 1;
        }
        & + label[class="app-legend__label head"] {
            position: relative;
            font-size: 0.9375rem;
            line-height: 1.5rem;
            display: inline-block;
            vertical-align: top;
            cursor: pointer;
            margin: 0 0.625rem;

            svg {
                pointer-events: none;
                position: absolute;
                width: 0.75rem;
                left: -1.625rem;
                top: var(--checkbox-height);
                transform: scale(var(--checkbox-scale));
                transition: transform .1s ease-in-out;
                stroke: var(--stroke, #fff);
                stroke-width: 5px;
            }
        }
        & + label[class="app-legend__label parent"] {
            position: relative;
            font-size: 0.9375rem;
            line-height: 1.5rem;
            display: inline-block;
            vertical-align: top;
            cursor: pointer;
            margin: 0 0.625rem;

            svg {
                --checkbox-scale: 0;
                pointer-events: none;
                position: absolute;
                width: 0.75rem;
                left: -1.625rem;
                top: var(--checkbox-height);
                transform: scale(var(--checkbox-scale));
                transition: transform .1s ease-in-out;
                stroke: var(--stroke, #fff);
                stroke-width: 5px;
            }
        }
    }
}
