@use "../../../node_modules/sass-rem" as *;
@use '../variables' as *;

.extensions-panel {
    &__container {
        position: absolute;
        max-height: 95vh;
        background-color: #fff;
        border-radius: rem(3px);
        transition: opacity 100ms;
        overflow-y: auto;
    }
    &__header {
        position: sticky;
        top: 0;
        padding: 0 1rem;
        display: flex;
        align-items: center;
        font-size: rem(18px);
        font-weight: 500;
        color: theme-color(dark-blue);
        height: 6rem;
        z-index: 10;
        background: white;

        &__close-icon {
            margin-left: auto;
            cursor: pointer;
            width: rem(24px);
            height: rem(24px);
        }
    }

    &__item {
        position: relative;
        border-radius: rem(3px);
        display: flex;
        align-items: center;
        color: #222f3e;

        &--clickable {
            margin: 0 -0.25rem .5rem;
            padding: .25rem;
            cursor: pointer;
            &:hover {
                background-color: rgba(#000, 0.05);
            }
        }

        &__icon {
            display: inline-block;
            width: rem(40px);
            height: rem(40px);
            border-radius: rem(3px);
            &:empty {
                background-color: #d1d1d1;
            }

            &--btn {
                cursor: pointer;
                background-color: #dbdde3;
                color: #222f3e;
                opacity: 0.5;
                transition: opacity 100ms;
                &:not(&--active):hover {
                    opacity: 0.7;
                }
                &--active {
                    opacity: 1;
                    cursor: auto;
                }
            }
        }

        &__arrow {
            position: absolute;
            right: .25rem;

            svg {
                height: rem(18px);
            }
        }
    }

    &__description {
        margin-top: rem(10px);
        font-size: rem(14px);
        color: rgba(34, 47, 62, 0.6);
    }
}
