@use "../../../node_modules/sass-rem" as *;
@use '../variables' as *;
@use '../functions' as *;

:root {
    --panel-width: 500px;
    --panel-large-width: 1024px;
    --map-left: 0;
}

.fixed-container {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
}

.app-container {
    pointer-events: none;
}
.pointer-events-none-self {
    pointer-events: none;
    & > * {
        pointer-events:auto;
    }
}
.zoom-controls-container {
    position: absolute;
    right: rem(15px);
    bottom: 0;
    padding: 0 0 24px 0;
    pointer-events: all;
}

.selection-list-container {
    position: absolute;
    top: 0;
    pointer-events: all;
    width: 100%;
    z-index: 100000;
    background-color: white;
}

body.resizing {
    cursor: col-resize;
}
body.resizing * {
    pointer-events: none;
    user-select: none;
}
