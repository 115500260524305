@use '../../node_modules/@angular/cdk/overlay-prebuilt.css';
@use "../../node_modules/@recognizebv/vwui-theme/dist/vwui-theme.css";
@use "../../node_modules/@recognizebv/vwui-core/dist/vwui-components/vwui-components.css";

@import "./functions";
@import "./variables";
@import "./bootstrap";
@import "./layout";

@import "./components/_index";
@import "./vendor/toastr";
@import "./vendor/ng-select";
@import "./vendor/mapbox-gl-draw";
@import "./vendor/vwui";
@import "./utils";

body {
    color: #222f3e;

    @screen desktop {
        overflow: hidden;
    }
}

.app-range {
    --thumb-size: 16px;

    appearance: none;
    width: 100%;
    background: linear-gradient(to right, rgba(var(--vwui-color-rgb-primary), var(--opacity)) var(--gradient-stop), rgba(var(--vwui-color-rgb-primary), var(--opacity)) var(--gradient-stop), rgba(255, 255, 255, 0.2) var(--gradient-stop), rgba(255, 255, 255, 0.2) 100%);
    border-radius: var(--vwui-size-radius-sm);
    height: 8px;

    &:focus {
        outline: 0;
    }

    &::-webkit-slider-thumb {
        appearance: none;
        width: var(--thumb-size);
        height: var(--thumb-size);
        background: var(--vwui-color-light);
        border-radius: var(--vwui-size-radius-round);
    }

    &::-moz-range-thumb {
        appearance: none;
        width: var(--thumb-size);
        height: var(--thumb-size);
        background: var(--vwui-color-light);
        border-radius: var(--vwui-size-radius-round);
    }

    &::-ms-thumb {
        appearance: none;
        width: var(--thumb-size);
        height: var(--thumb-size);
        background: var(--vwui-color-light);
        border-radius: var(--vwui-size-radius-round);
    }

    &::-ms-track {
        width: 100%;
        cursor: pointer;
        background: transparent;
        border-color: transparent;
        color: transparent;
    }
}

//Hide mapbox-gl-draw delete button (needed because keyboard events are ignored without button)
.mapbox-gl-draw_ctrl-draw-btn.mapbox-gl-draw_trash {
    display: none;
}



.z-index-1090 {
    z-index: 1090;
}

table.rounded {
    /* top-left border-radius */
    & tr:first-child th:first-child {
        @apply rounded-tl;
    }
    /* top-right border-radius */
    & tr:first-child th:last-child {
        @apply rounded-tr;
    }
    /* bottom-left border-radius */
    & tr:last-child td:first-child {
        @apply rounded-bl;
    }
    /* bottom-right border-radius */
    & tr:last-child td:last-child {
        @apply rounded-br;
    }
}
.rounded-table-neutral-3
{
    border-spacing: 0;
    border-collapse: separate;

    & tr:first-child th, & tr:first-child td {
        @apply border-t;
    }

    & td, & th {
        @apply border-b border-neutral-3;
    }

    & td:first-child, & th:first-child {
        @apply border-l;
    }
    & td:last-child, & th:last-child {
        @apply border-r;
    }

    & tr:first-child > :first-child {
        @apply rounded-tl;
    }

    & tr:first-child > :last-child {
        @apply rounded-tr;
    }

    & tr:last-child > :first-child {
        @apply rounded-bl;
    }

    & tr:last-child > :last-child {
        @apply rounded-br;
    }
}
