@use "../../node_modules/sass-rem" as *;

.actions-container {
    position: absolute;
    display: flex;
    bottom: calc(346px - 20px);
    right: 0;
    padding: rem(15px) rem(15px) 0;
    pointer-events: all;
}
