.d-none {
    display: none;
}

.loading-blur {
    position: relative;
    filter: blur(5px);
    &::after {
        content: '';
        @apply absolute block inset-0 bg-white bg-opacity-80;
    }
}

.hide-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
}
