@use '../variables' as *;
@use "../../../node_modules/sass-rem" as *;
app-share-button {
    display: inline-block;
}
.share-button {
    position: relative;
    margin-left: rem(12px);

    display: flex;
    width: rem(40px);
    height: rem(40px);
    background-color: theme-color(light);
    border-radius: $border-radius-base;
    box-shadow: $shadow-base;
    padding: rem(5px);
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: theme-color(dark);

    svg {
        width: rem(20px);
    }
}
