app-layers {
    .save-button svg {
        width: 24px;
    }
    .map-styles {
        display: grid;
        grid-template-columns: repeat (auto-fit, minmax (6.25rem, 1fr));
        grid-gap: 0.5rem;
    }
    .map-style-content {
        width: 100%;
        padding-top: 75%;
    }
    .map-style-gradient {
        background-image: linear-gradient (to bottom, rgba (0, 0, 0, 0) 0%, rgba (0, 56, 76, 0.56));
    }

    .mode-radio {
        @apply flex items-center relative h-12 px-4 py-3;
        font-size: 0.9375rem;
        line-height: 1.5rem;

        input {
            @apply text-dark border border-neutral-2 ml-3;
            appearance: none;
            width: 1.25rem;
            height: 1.25rem;
            border-radius: 50%;

            &:checked, &:hover {
                border-width: 7px;
            }

            &::before {
                @apply block absolute inset-0 rounded border border-neutral-2;
                content: '';
            }

            &:checked {
                @apply border-primary;
                &::before {
                    @apply border-primary
                }
            }
        }
    }
}
