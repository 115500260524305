@use "sass:color";
@use "sass:map";
$ng-select-highlight: #008cbd !default;
$ng-select-primary-text: #000 !default;
$ng-select-disabled-text: #f9f9f9 !default;
$ng-select-border: #e1e5f1 !default;
$ng-select-border-radius: 4px !default;
$ng-select-bg: #ffffff !default;
$ng-select-selected: color.adjust($ng-select-highlight, $lightness: 46%) !default;
$ng-select-marked: color.adjust($ng-select-highlight, $lightness: 48%) !default;
$ng-select-box-shadow: var(--vwui-shadow-base) !default;
$ng-select-placeholder: var(--vwui-color-neutral-1) !default;
$ng-select-height: 3rem !default;
$ng-select-value-padding-left: 10px !default;
$ng-select-value-font-size: 0.9em !default;

.ng-select {
    &.ng-invalid > .ng-select-container {
        border-color: map.get($theme-colors, danger);
    }

    &.ng-select-opened {
        > .ng-select-container {
            background: $ng-select-bg;
            border-color: color.adjust($ng-select-border, $lightness: -10%) $ng-select-border color.adjust($ng-select-border, $lightness: 5%);

            &:hover {
                box-shadow: none;
            }

            .ng-arrow {
                top: -2px;
                border-color: transparent transparent color.adjust($ng-select-border, $lightness: -20%);
                border-width: 0 5px 5px;

                &:hover {
                    border-color: transparent transparent color.adjust($ng-select-border, $lightness: -60%);
                }
            }
        }

        &.ng-select-bottom {
            > .ng-select-container {
                border-bottom-right-radius: 0;
                border-bottom-left-radius: 0;
            }
        }

        &.ng-select-top {
            > .ng-select-container {
                border-top-right-radius: 0;
                border-top-left-radius: 0;
            }
        }
    }

    &.ng-select-focused {
        &:not(.ng-select-opened) > .ng-select-container {
            border-color: $ng-select-highlight;
            box-shadow: $ng-select-box-shadow;
        }
    }

    &.ng-select-disabled {
        > .ng-select-container {
            background-color: $ng-select-disabled-text;
        }
    }

    .ng-has-value .ng-placeholder {
        display: none;
    }

    .ng-select-container {
        color: $ng-select-primary-text;
        background-color: $ng-select-bg;
        border-radius: $ng-select-border-radius;
        border: 1px solid $ng-select-border;
        min-height: $ng-select-height;
        align-items: center;

        &:hover {
            box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
        }

        .ng-value-container {
            align-items: center;
            padding-left: $ng-select-value-padding-left;

            .ng-placeholder {
                color: $ng-select-placeholder;
            }
        }
    }

    &.ng-select-single {
        .ng-select-container {
            height: $ng-select-height;

            .ng-value-container {
                .ng-input {
                    top: 5px;
                    left: 0;
                    padding-left: $ng-select-value-padding-left;
                    padding-right: 50px;
                }
            }
        }
    }

    &.ng-select-multiple {
        &.ng-select-disabled {
            > .ng-select-container .ng-value-container .ng-value {
                background-color: $ng-select-disabled-text;
                border: 1px solid color.adjust($ng-select-border, $lightness: 10%);

                .ng-value-label {
                    padding: 0 5px;
                }
            }
        }

        .ng-select-container {
            .ng-value-container {
                padding-top: 5px;
                padding-left: 7px;

                .ng-value {
                    font-size: $ng-select-value-font-size;
                    margin-bottom: 5px;
                    background-color: $ng-select-selected;
                    border-radius: 2px;
                    margin-right: 5px;

                    &.ng-value-disabled {
                        background-color: $ng-select-disabled-text;

                        .ng-value-label {
                            padding-left: 5px;

                        }
                    }

                    .ng-value-label {
                        display: inline-block;
                        padding: 1px 5px;
                    }

                    .ng-value-icon {
                        display: inline-block;
                        padding: 1px 5px;

                        &:hover {
                            background-color: color.adjust($ng-select-selected, $lightness: -5%);
                        }

                        &.left {
                            border-right: 1px solid color.adjust($ng-select-selected, $lightness: -10%);

                        }

                        &.right {
                            border-left: 1px solid color.adjust($ng-select-selected, $lightness: -10%);

                        }
                    }
                }

                .ng-input {
                    padding: 0 0 3px 3px;
                }

                .ng-placeholder {
                    top: 5px;
                    padding-bottom: 5px;
                    padding-left: 3px;
                }
            }
        }
    }

    .ng-clear-wrapper {
        color: color.adjust($ng-select-border, $lightness: -20%);

        &:hover .ng-clear {
            color: #D0021B;
        }
    }

    .ng-spinner-zone {
        padding: 5px 5px 0 0;
    }

    .ng-arrow-wrapper {
        width: 25px;
        padding-right: 5px;

        &:hover {
            .ng-arrow {
                border-top-color: color.adjust($ng-select-border, $lightness: -40%);
            }
        }

        .ng-arrow {
            border-color: color.adjust($ng-select-border, $lightness: -20%) transparent transparent;
            border-style: solid;
            border-width: 5px 5px 2.5px;
        }
    }
}

.ng-dropdown-panel {
    background-color: $ng-select-bg;
    border: 1px solid $ng-select-border;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
    left: 0;

    &.ng-select-bottom {
        top: 100%;
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;
        border-top-color: color.adjust($ng-select-border, $lightness: 10%);
        margin-top: -1px;

        .ng-dropdown-panel-items {
            .ng-option {
                &:last-child {
                    border-bottom-right-radius: 4px;
                    border-bottom-left-radius: 4px;
                }
            }
        }
    }

    &.ng-select-top {
        bottom: 100%;
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
        border-bottom-color: color.adjust($ng-select-border, $lightness: 10%);
        margin-bottom: -1px;

        .ng-dropdown-panel-items {
            .ng-option {
                &:first-child {
                    border-top-right-radius: 4px;
                    border-top-left-radius: 4px;
                }
            }
        }
    }

    .ng-dropdown-header {
        border-bottom: 1px solid $ng-select-border;
        padding: 5px 7px;
    }

    .ng-dropdown-footer {
        border-top: 1px solid $ng-select-border;
        padding: 5px 7px;
    }

    .ng-dropdown-panel-items {
        .ng-optgroup {
            user-select: none;
            padding: 8px 10px;
            font-weight: 500;
            color: rgba(0, 0, 0, 0.54);
            cursor: pointer;

            &.ng-option-disabled {
                cursor: default;
            }

            &.ng-option-marked {
                background-color: $ng-select-marked;
            }

            &.ng-option-selected, &.ng-option-selected.ng-option-marked {
                background-color: $ng-select-selected;
                font-weight: 600;
            }
        }

        .ng-option {
            background-color: $ng-select-bg;
            color: rgba(0, 0, 0, .87);
            padding: 8px 10px;

            &.ng-option-selected, &.ng-option-selected.ng-option-marked {
                color: $ng-select-primary-text;
                background-color: $ng-select-selected;

                .ng-option-label {
                    font-weight: 600;
                }
            }

            &.ng-option-marked {
                background-color: $ng-select-marked;
                color: $ng-select-primary-text;
            }

            &.ng-option-disabled {
                color: color.adjust($ng-select-primary-text, $lightness: 60%);
            }

            &.ng-option-child {
                padding-left: 22px;
            }

            .ng-tag-label {
                font-size: 80%;
                font-weight: 400;
                padding-right: 5px;
            }
        }
    }
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
    background: var(--vwui-color-neutral-3);
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
    background: var(--vwui-color-neutral-3);
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.right {
    border-left: 1px solid var(--vwui-color-neutral-1);
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon {
    color: #777;

    &:hover {
        background-color: var(--vwui-color-neutral-2);
    }
}

.ng-select.ng-select-multiple.ng-select-disabled > .ng-select-container .ng-value-container .ng-value {
    background: var(--vwui-color-neutral-3);
    border: none;
}

.ng-select.ng-select-disabled > .ng-select-container {
   border: 1px solid var(--vwui-color-neutral-3);
}

.ng-select.ng-select-multiple.ng-select-disabled>.ng-select-container .ng-value-container .ng-value .ng-value-icon {
    display: inline-block !important;
    cursor: default;

    &:hover {
        background-color: var(--vwui-color-neutral-3);
    }
}

.ng-select.ng-select-multiple.ng-select-disabled > .ng-select-container .ng-value-container .ng-value .ng-value-label {
    padding: 1px 6px;
}
