.vwui-range {
    --range-primary-color: theme('colors.primary.DEFAULT');
    --range-secondary-color: theme('colors.neutral.3');
    --range-button-color: theme('colors.white');
    --range-disabled-color: theme('colors.neutral.2');
    --range-position: 0%;

    appearance: none;
    width: 80px;
    background: transparent;

    @mixin thumb {
        -webkit-appearance: none;
        box-sizing: border-box;
        width: 12px;
        height: 12px;
        background: var(--range-button-color);
        border: solid 1px var(--range-primary-color);
        border-radius: 12px;
        box-shadow: 0 2px 4px 0 rgba(0, 56, 76, 0.24);
        cursor: default;
    }

    @mixin track {
        width: 100%;
        cursor: default;
        background: linear-gradient(
            to right,
            var(--range-primary-color) 0%,
            var(--range-primary-color) var(--range-position),
            var(--range-secondary-color) var(--range-position),
            var(--range-secondary-color) 100%
        );
        height: 4px;
        border-radius: 3px;
    }

    &:focus {
        outline: none;
    }

    &:disabled, &.disabled {
        --range-primary-color: var(--range-disabled-color);
        --range-secondary-color: var(--range-disabled-color);
    }

    &::-webkit-slider-thumb {
        @include thumb;
        margin-top: -4px;
    }

    &::-moz-range-thumb {
        @include thumb;
    }

    &::-webkit-slider-runnable-track {
        @include track;
    }

    &::-moz-range-track {
        @include track;
    }
}
