
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
    background: var(--vwui-color-neutral-3);
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
    top: 50%;
    transform: translateY(-50%);
    padding-bottom: 0;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
    background-color: var(--vwui-color-neutral-4);
    padding-left: 10px;
}

.ng-dropdown-panel {
    box-shadow: var(--vwui-shadow-large);
}

.ng-dropdown-panel .ng-dropdown-panel-items {
    max-height: 160px;
}
