@use "sass:map";
@use "../../../node_modules/sass-rem" as *;

@use '../variables' as *;
@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/variables';
@import '../../../node_modules/bootstrap/scss/mixins';

.modal-content {
    overflow: hidden;
}

.app-modal {
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.3);
}

.app-modal__header {
    display: flex;
    align-items: center;
    background-image: linear-gradient(to left, #4f667e, #273648);
    height: rem(60px);
    padding: 0 rem(16px);
    color: map.get($theme-colors, light);
}

.app-modal__title {
    font-size: 20px;
    font-weight: 500;
}

.app-modal__close-btn {
    width: rem(24px);
    margin-left: auto;
}

.app-modal__body {
    padding: rem(16px);
}

.app-modal__footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    border-top: 1px solid map.get($theme-colors, light-gray);
}

.app-modal__button {
    outline: none;
    background: none;
    text-transform: uppercase;
    padding: 10px;
    border: 10px solid white;
    color: map.get($theme-colors, medium-gray);
    font-weight: var(--vwui-font-weight-semibold);
    font-size: 14px;
    display: flex;
    flex-direction: row;
    align-items: center;

    &__loading {
        width: 1.25rem;
        height: 1.25rem;
        margin-left: 0.5rem;

        svg {
            width: 100%;
            height: 100%;
        }
    }

    &:focus {
        outline: none;
    }

    &:hover {
        background: map.get($theme-colors, white-gray);
        cursor: pointer;
    }

    &:active {
        background: map.get($theme-colors, lighter-gray);
    }

    &--primary {
        color: map.get($theme-colors, primary);
    }
}
.app-modal_tour
{
    max-width:650px;
}

.modal-content
{
    max-height: calc(100vh - 1rem);
}

@include media-breakpoint-up(sm) {
    .modal-content {
        max-height: calc(100vh - 3.5rem);
    }
}

.modal-lg {
    --max-width: var(--vwui-modal--max-width, 50rem);
}
.modal-xl {
    --max-width: var(--vwui-modal--max-width, 90vw);
    --max-height: var(--vwui-modal--max-width, 90vh);
}
.modal-close {
    --size: 2rem;
    --icon-size: 1.25rem;
}
