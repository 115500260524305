:root {
    --vwui-color-neutral-1: #6D7690;
    --vwui-color-rgb-neutral-1: 109, 118, 114;
}

.vwui-container {
    width: 100%;
}

//.vwui-button.sc-vwui-button {
//    --height: 2rem;
//}

.spinner {
    --color: theme('colors.neutral.1')
}

vwui-btn-icon.sc-vwui-btn-icon-h {
    --bg: var(--vwui-color-neutral-4);
    --bg-hover: var(--vwui-color-neutral-3);
}

@screen mobile {
    a:hover, .text-link:hover {
        text-decoration: none;
    }

    .modal__content.sc-vwui-modal {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        transform: none;
        max-width: 100%;
        max-height: 100%;
        border-radius: 0;
    }

    .app-modal-header {
        .modal-close {
            display: none;
        }

        .modal-header {
            padding-left: 0;
        }

    }

    .modal-body.sc-vwui-modal-body {
        padding: var(--vwui-size-spacing-md);
    }
}
