@use "sass:color";
@use "sass:map";
@use "../../../node_modules/sass-rem" as *;
@use '../../../node_modules/ngx-toastr/toastr';

.overlay-container {
    pointer-events: none;
    opacity:1;

    > .toast-container {
        visibility: visible;
        margin-top: rem(16px);
    }
}

.toast-container .ngx-toastr {
    display: flex;
    align-items: center;
    border-radius: 4px;
    box-shadow: 0 30px 20px -10px rgba(0, 56, 76, 0.16), -2px 0 0 0 var(--background-color, #00384C) !important;
    padding: rem(16px) rem(50px) rem(16px) rem(50px);
}

.toast-container.toast-top-center .ngx-toastr {
    max-width: 420px;
}

.toast-success {
    --background-color: #{color.adjust(#51A351, $lightness: 10%)};
}
.toast-error {
    --background-color: #{color.adjust(#BD362F, $lightness: 10%)};
}
.toast-warning {
    --background-color: #{color.adjust(#F89406, $lightness: 10%)};
}

.toast-info {
    padding: rem(25px) rem(25px) rem(25px) rem(25px);
    color: #00384c;
    &::before {
        display: inline-block;
        content: " ";
        width: rem(16rem);
        height: rem(16rem);
        padding-right: rem(50px);
        background-image: url(../../assets/icons/toastr-info.svg);
        color: #00384c;
        background-repeat: no-repeat;
    }

    background-color: map.get($theme-colors, "light");

    .toast-message {
        font-family: Heebo, sans-serif;
        font-weight: 500;
        font-size: rem(15px);
        color: #00384c;
    }
}

.toast-error {
    padding: rem(25px) rem(25px) rem(25px) rem(25px);
    color: #00384c;
    &::before {
        display: inline-block;
        content: " ";
        width: 1rem;
        height: 1rem;
        padding-right: rem(50px);
        background-size: 1rem;
        background-image: url(../../assets/icons/icon-24-close.svg);
        color: #BD362F;
        background-repeat: no-repeat;
    }

    background-color: map.get($theme-colors, "light");

    .toast-message {
        font-family: Heebo, sans-serif;
        font-weight: 500;
        font-size: rem(15px);
        color: #00384c;
    }
}
.toast-success {
    &::before {
        display: inline-block;
        content: " ";
        width: rem(16rem);
        height: rem(16rem);
        padding-right: rem(24px);
        background-image: url(../../assets/icons/toastr-success.svg);
        background-repeat: no-repeat;
    }

    background-color: map.get($theme-colors, "light");

    .toast-message {
        font-family: Heebo, sans-serif;
        font-weight: 500;
        font-size: rem(15px);
        color: #00384c;
    }
}
