@use "sass:map";
@use "../../../node_modules/sass-rem" as *;

@use '../variables' as *;
.map-service {
    position: fixed;
    top: 5rem;
    left: 1.5rem;
    background: #fff;
    width: 375px;
    padding: 1rem;
}

.form-field {
    margin-bottom: 1.5rem;
}

.form-label {
    display: block;
    font-weight: 500;
    padding-bottom: .5rem;
    margin: 0;
    color: map.get($theme-colors, 'dark-blue');
}

.form-label-parentheses {
    color: #7A828B;
}

.form-option-group {
    display: flex;
    width: 100%;
}

.form-option {
    position: relative;
    display: block;
    flex: 1;

    &:first-child {
        margin-right: .5rem;
    }
}

.form-option__label {
    --radio-size: 1.25rem;
    --radio-inner-size: .5rem;
    --radio-scale: 0;
    --border-color: var(--vwui-color-neutral-3);

    input:disabled + & {
        cursor: not-allowed;
        background-color: rgba(#222f3e, 0.1);
        &::before {
            border-color: rgba(#222f3e, 0.3);
        }
    }

    position: relative;
    display: block;
    border: 1px solid var(--border-color);
    border-radius: var(--vwui-size-radius-sm);
    padding: var(--vwui-size-spacing-sm) var(--vwui-size-spacing-md);
    color: var(--vwui-color-dark);
    transition: border-color .15s ease-in-out;

    &::before,
    &::after {
        content: "";
        display: block;
        position: absolute;
        right: .5rem;
        top: 50%;
        border-radius: 100%;
    }

    &::before {
        width: var(--radio-size);
        height: var(--radio-size);
        transform: translateY(-50%);
        border: 1px solid var(--border-color);
        transition: border-color .15s ease-in-out;
    }

    &::after {
        width: var(--radio-inner-size);
        height: var(--radio-inner-size);
        transform: translate(calc(50% + (var(--radio-size) / 2) * -1), -50%) scale(var(--radio-scale));
        background: var(--vwui-color-primary);
        transition: transform .05s ease-in-out;
    }
}

.form-option__input,
.form-radio__input {
    position: absolute;
    width: 1px;
    height: 1px;
    opacity: 0;
    z-index: -1;
}

.form-option__input:checked ~ .form-option__label {
    --radio-scale: 1;
    --border-color: var(--vwui-color-primary);
    --text-color: var(--vwui-color-dark);
}

.form-radio {
    position: relative;
    display: block;
}

.form-radio__label {
    --radio-size: 1.25rem;
    --radio-inner-size: .5rem;
    --radio-scale: 0;
    --border-color: var(--vwui-color-neutral-3);

    position: relative;
    display: block;
    padding-left: calc(var(--radio-size) + .5rem);
    font-size: 14px;
    color: var(--vwui-color-dark);
    transition: border-color .15s ease-in-out;

    &::before,
    &::after {
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        left: 0;
        border-radius: 100%;
    }

    &::before {
        width: var(--radio-size);
        height: var(--radio-size);
        border: 1px solid var(--border-color);
        transform: translateY(-50%);
        transition: border-color .15s ease-in-out;
    }

    &::after {
        width: var(--radio-inner-size);
        height: var(--radio-inner-size);
        background: var(--vwui-color-primary);
        transform: translate(calc(25% + (var(--radio-inner-size) / 2)), -50%) scale(var(--radio-scale));
        transition: transform .05s ease-in-out;
    }
}

.form-radio__input:checked ~ .form-radio__label {
    --radio-scale: 1;
    --border-color: var(--vwui-color-primary);
}

.form-input {
    border: 1px solid var(--vwui-color-neutral-3);
    border-radius: var(--vwui-size-radius-sm);
    width: 100%;
    height: 3rem;
    padding: 0 var(--vwui-size-spacing-sm);
    color: var(--vwui-color-dark);
    font-family: var(--vwui-font-family-base);

    &::placeholder {
        color: var(--vwui-color-neutral-1);
    }

    &:focus {
        border-color: var(--vwui-color-primary);
    }

    &.ng-touched.ng-invalid {
        border-color: var(--vwui-color-error);
    }
}

textarea.form-input {
    min-height: rem(77px);
    padding: rem(16px);
}

.form-button-container {
    display: flex;
    justify-content: flex-end;
}

.trimble-login-container {
    display: flex;
    flex-direction: column;

    &__button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 156px;
        height: 60px;
        background: #f7f7f7;
        border-radius: var(--vwui-size-radius-sm);
        border: 0;
        margin-top: var(--vwui-size-spacing-xs);
        transition: opacity .15s;

        &:focus {
            outline: 0;
        }

        &:active {
            opacity: .5;
        }
    }
}
