/**
 * Do not edit directly
 * Generated on Wed, 06 Jul 2022 09:54:02 GMT
 */

:root {
  --vwui-border-color-base: var(--vwui-color-neutral-1);
  --vwui-border-color-light: var(--vwui-color-neutral-3);
  --vwui-border-size-base: 1px;
  --vwui-border-type-base: solid;
  --vwui-border-base: var(--vwui-border-size-base) var(--vwui-border-type-base) var(--vwui-border-color-base);
  --vwui-border-light: var(--vwui-border-size-base) var(--vwui-border-type-base) var(--vwui-border-color-light);
  --vwui-color-primary: #008cbc;
  --vwui-color-secondary: #e6f4f8;
  --vwui-color-dark: #00384c;
  --vwui-color-light: #ffffff;
  --vwui-color-info: #008cbc;
  --vwui-color-success: #36b37a;
  --vwui-color-error: #ff5640;
  --vwui-color-danger: #ff5640;
  --vwui-color-required: #ff5640;
  --vwui-color-warning: #ffab00;
  --vwui-color-neutral-1: #a6afca;
  --vwui-color-neutral-2: #c2c9dd;
  --vwui-color-neutral-3: #e1e5f1;
  --vwui-color-neutral-4: #eff1f8;
  --vwui-color-rgb-primary: 0,140,188;
  --vwui-color-rgb-secondary: 230,244,248;
  --vwui-color-rgb-dark: 0,56,76;
  --vwui-color-rgb-light: 255,255,255;
  --vwui-color-rgb-info: 0,140,188;
  --vwui-color-rgb-success: 54,179,122;
  --vwui-color-rgb-error: 255,86,64;
  --vwui-color-rgb-danger: 255,86,64;
  --vwui-color-rgb-required: 255,86,64;
  --vwui-color-rgb-warning: 255,171,0;
  --vwui-color-rgb-neutral-1: 166,175,202;
  --vwui-color-rgb-neutral-2: 194,201,221;
  --vwui-color-rgb-neutral-3: 225,229,241;
  --vwui-color-rgb-neutral-4: 239,241,248;
  --vwui-font-family-base: Heebo, Helvetica Neue, Arial, sans-serif;
  --vwui-font-line-height-base: 1.5;
  --vwui-font-weight-regular: 400;
  --vwui-font-weight-semibold: 500;
  --vwui-shadow-base: 0 2px 4px rgba(var(--vwui-color-rgb-dark), .04);
  --vwui-shadow-large: 0 30px 20px -10px rgba(var(--vwui-color-rgb-dark), .16);
  --vwui-shadow-focus: 0 0 0 3px rgba(var(--vwui-color-rgb-neutral-1), .5);
  --vwui-shadow-focus-primary: 0 0 0 3px rgba(var(--vwui-color-rgb-primary), .5);
  --vwui-size-component-nav: 18.75rem; /* The width of the nav bar in default state */
  --vwui-size-component-nav-collapsed: 5rem; /* The width of the nav bar in collapsed state */
  --vwui-size-component-header: 6.25rem; /* The height of the header */
  --vwui-size-component-footer: 5rem; /* The height of the footer */
  --vwui-size-font-xs: 0.75rem; /* the xsmall size of the font */
  --vwui-size-font-sm: 0.875rem; /* the small size of the font */
  --vwui-size-font-md: 0.9375rem; /* the medium size of the font */
  --vwui-size-font-lg: 1rem; /* the large size of the font */
  --vwui-size-font-xl: 1.25rem; /* the large size of the font */
  --vwui-size-font-xxl: 1.5rem; /* the large size of the font */
  --vwui-size-font-base: var(--vwui-size-font-md);
  --vwui-size-font-heading-1: var(--vwui-size-font-xxl); /* The font size for a h1 heading */
  --vwui-size-font-heading-2: var(--vwui-size-font-xl); /* The font size for a h2 heading */
  --vwui-size-font-heading-3: var(--vwui-size-font-lg); /* The font size for a h3 heading */
  --vwui-size-icon-base: 1.5rem;
  --vwui-size-radius-sm: 0.25rem;
  --vwui-size-radius-md: 0.5rem;
  --vwui-size-radius-lg: 1rem;
  --vwui-size-radius-round: 100%;
  --vwui-size-spacing-xxs: 0.25rem; /* This spacing unit is used to create a xxsmall spacing between components. */
  --vwui-size-spacing-xs: 0.5rem; /* This spacing unit is used to create a xsmall spacing between components. */
  --vwui-size-spacing-sm: 0.75rem; /* This spacing unit is used to create a small spacing between components. */
  --vwui-size-spacing-md: 1rem; /* This spacing unit is used to create a medium spacing between components. */
  --vwui-size-spacing-lg: 1.5rem; /* This spacing unit is used to create a large spacing between components. */
  --vwui-size-spacing-xl: 2rem; /* This spacing unit is used to create a xlarge spacing between components. */
  --vwui-size-spacing-xxl: 2.5rem; /* This spacing unit is used to create a xxlarge spacing between components. */
  --vwui-size-spacing-base: var(--vwui-size-spacing-md);
  --vwui-transition-timing-faster: .15s;
  --vwui-transition-timing-fast: .25s;
  --vwui-transition-timing-slow: .5s;
  --vwui-transition-easing-inout: ease-in-out;
  --vwui-transition-easing-inoutback: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  --vwui-transition-base: var(--vwui-transition-timing-faster) var(--vwui-transition-easing-inout);
}
