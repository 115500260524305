@use "sass:string";
@use '../variables' as *;
.energy-label {
    position: relative;
    line-height: 1rem;
    background: #b8ada2;

    &::after {
        content: ' ';
        display: block;
        position: absolute;
        left: calc(100% - 0.125rem);
        top: 0;
        bottom: 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-color: transparent;
        border-top-width: 0.5rem;
        border-bottom-width: 0.5rem;
        border-left-width: 0.5rem;
    }

    @each $label, $color in $energy-label-colors {
        &--#{string.to-upper-case($label)} {
            display: inline-block;
            background: $color;
            height: 1rem;
            width: 3.5rem;
            border-radius: 2px;
            font-size: 0.75rem;
            padding: 0 0.5rem;
            color: white;
            font-weight: bold;

            &::after {
                border-left-color: $color;
            }
        }
    }
}
