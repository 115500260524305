@use "../../../node_modules/sass-rem" as *;
@use '../variables' as *;
@use '../functions' as *;
app-zoom-controls {
    .zoom-controls {
        display: flex;
        flex-direction: column;
    }

    vwui-button {
        --rotation: 0deg;
        margin: 2px;
        display: flex;
        align-items: center;
        color: theme-color(dark-blue);

        svg {
            transform: rotate(var(--rotation));
        }

        button.vwui-button {
            width: 32px;
            height: 32px;
        }

        button.vwui-button--icon-only {
            --radius: 4px;
        }

        .vwui-button.sc-vwui-button {
            --height: 2rem;
        }

        &:last-child button.vwui-button--icon-only {
            border-bottom-left-radius: rem(5px);
            border-bottom-right-radius: rem(5px);
            border-top: none;
        }
    }
}

.measure-tool-marker {
    margin-top: -40px;
    background-color: white;
    display: block;
    font-size: rem(15px);
    cursor: pointer;
    padding:  rem(5px);

    span.blue {
        color: #008cbc;
    }
}
