// See app-search-filter-select-checkbox for required html
.custom-checkbox {
    display: flex;
    align-items: center;
    min-width: 100px;

    input[type='checkbox'] {
        --color: #{theme('colors.primary.DEFAULT')};
        --border: #{theme('colors.neutral.2')};
        --background: #fff;

        -webkit-appearance: none;
        -moz-appearance: none;
        outline: none;

        flex: none;
        position: relative;
        height: 1.25rem;
        width: 1.25rem;
        border-radius: 2px;
        margin: 0;

        display: inline-block;
        vertical-align: top;
        cursor: pointer;

        border: 1px solid var(--bc, var(--border));
        background: var(--b, var(--background));
        background-position: -1px -1px;
        transition: background .3s, border-color .3s, box-shadow .2s;

        &:checked {
            --b: var(--color);
            --bc: var(--color);
        }

        &:disabled {
            --b: var(--disabled);
            cursor: initial;
            opacity: .9;

            &:checked {
                --b: var(--color);
                --bc: var(--color);
            }

            & + label {
                cursor: initial;
            }
        }

        &:focus {
            box-shadow: 0 0 0 2px var(--color);
        }

        &:after {
            opacity: var(--o, 0);
        }

        &:checked {
            --o: 1;
        }

        &:checked + label svg {
            --checkbox-scale: 1;
        }

        & + label {
            position: relative;
            font-size: 0.9375rem;
            line-height: 1.5rem;
            display: inline-block;
            vertical-align: top;
            cursor: pointer;
            margin: 0 1rem;

            svg {
                pointer-events: none;
                --checkbox-scale: 0;
                position: absolute;
                width: 0.75rem;
                left: -2rem;
                top: 50%;
                transform: translateY(-50%) scale(var(--checkbox-scale));
                transition: transform .1s ease-in-out;
                stroke: var(--stroke, #fff);
                stroke-width: 5px;
            }
        }
    }
}

.grey-checkbox {
    input[type='checkbox'] {
        --color: #C2C9DD;
    }
}
