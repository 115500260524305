.extension-map-service {
    &__marker {
        display: flex;
        justify-items: center;
        background-color: #ff5640;
        border-radius: 4px;

        span {
            display: inline-block;
            margin: 20px;
            font-family: Heebo, sans-serif;
            font-size: 15px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            color: #ffffff;
        }
    }

    &__exclamation-mark {
        background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSIxNnB4IiBoZWlnaHQ9IjE2cHgiIHZpZXdCb3g9IjAgMCAxNiAxNiIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4gICAgPHRpdGxlPkdyb3VwPC90aXRsZT4gICAgPGcgaWQ9IlBhZ2UtMSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+ICAgICAgICA8ZyBpZD0iR3JvdXAiPiAgICAgICAgICAgIDxjaXJjbGUgaWQ9Ik92YWwiIGZpbGw9IiNGRkZGRkYiIGN4PSI4IiBjeT0iOCIgcj0iOCI+PC9jaXJjbGU+ICAgICAgICAgICAgPHBvbHlnb24gaWQ9IlBhdGgiIGZpbGw9IiNGRjU2NDAiIHBvaW50cz0iNy4wMDU4Mjg4NiAzLjAwNzgxMjUgNy4wMDU4Mjg4NiA4Ljk4NzU0ODgzIDguOTk3MjUzNDIgOC45ODc1NDg4MyA4Ljk5NzI1MzQyIDMuMDA3ODEyNSI+PC9wb2x5Z29uPiAgICAgICAgICAgIDxwb2x5Z29uIGlkPSJQYXRoIiBmaWxsPSIjRkY1NjQwIiBwb2ludHM9IjcgMTAgNyAxMiA4Ljk5MTQyNDU2IDEyIDguOTkxNDI0NTYgMTAiPjwvcG9seWdvbj4gICAgICAgIDwvZz4gICAgPC9nPjwvc3ZnPg==);
        display: inline-block;
        width: 16px;
        height: 16px;
        content: " ";
        margin-bottom: -3px;
        margin-right: 10px;
    }
}
