@use "../../../node_modules/sass-rem" as *;
@use '../variables' as *;
.overlay-container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin-left: 80px;
    opacity: 0;
    transition: margin-left .25s ease-in-out, opacity .15s ease-in-out;
    will-change: margin-left;
    visibility: hidden;

    // Overlay will have margin-left on tablet landscape and desktop devices
    @media screen and (min-width: 1024px) {
        &.info-bar-open {
            margin-left: 600px;
        }
    }

    &.is-visible {
        opacity: 1;
        visibility: visible;
        pointer-events: all;
    }

    &.is-header-only {
        pointer-events: none;
        .overlay-container__header {
            pointer-events: auto;
        }
        .overlay-container__content {
            display: none;
        }
    }

    &.no-header {
        .overlay-container__header {
            display: none;
        }
    }

    &__header {
        display: flex;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: rem(80px);
        background-color: #fff;
        box-shadow: $shadow-base;
        align-items: center;
        padding: 0 rem(30px) 0 rem(60px);
        color: #222f3e;
        z-index: 10;
    }

    &__title {
        font-size: rem(22px);
        flex: 1;
    }

    &__close-btn {
        display: flex;
        width: rem(30px);
        height: rem(30px);
        align-items: center;
        justify-content: center;
        cursor: pointer;

        svg {
            width: rem(24px);
        }
    }

    &__content {
        position: absolute;
        top: rem(80px);
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #FFFFFf;

        &__iframe {
            border: none;
            width: 100%;
            height: 100%;
        }

        &__no-header {
            top: 0;
        }
    }
}
