@use "sass:map";
@use "../../../node_modules/sass-rem" as *;
@use '../variables' as *;
.faq {
    margin-top: rem(-16px);
}

.faq__category > h2 {
    margin: rem(24px) rem(24px) rem(16px) rem(24px)
}

.faq__question {
    display: flex;
    align-items: center;

    padding: rem(13px) rem(24px);
    background-color: map.get($theme-colors, 'light-blue');
}

.faq__question__openclose {
    width: rem(14px);
    height: rem(14px);
    margin-left: auto;

    background-image: url(../../assets/icons/icon-chevron-down.svg);
    background-repeat: no-repeat;
    background-size: contain;

    &--open {
        transform: rotate(180deg);
    }
}

.faq__answer {
    padding: rem(16px) rem(24px);
}
