@use "scss/global";
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    body {
        @apply text-sm
    }
    h1 {
        @apply text-lg font-medium leading-8
    }
    h2 {
        @apply text-base font-medium leading-8
    }
    h3 {
        @apply text-m font-medium leading-6
    }
    button {
        @apply focus:outline-none
    }
    button:focus-visible {
        outline: 1px dotted;
        outline: 5px auto -webkit-focus-ring-color;
    }

    // Make tailwind hidden more important by repeating it below all other css
    .hidden {
        display: none;
    }

    svg {
        display: inline;
    }

    .tooltip .tooltip-inner {
        background: var(--vwui-color-dark);
        color: white;
        border-radius: var(--vwui-size-radius-sm);
    }

    .tooltip-fading {
        opacity: 0 !important;
        transition: opacity 0.5s ease-in-out;
    }

    @keyframes show-tooltip {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    .tooltip.show {
        animation: show-tooltip 0.5s ease-in-out;
    }
}

.mapboxgl-popup-content{
    width: 300px;
    font-family: 'Heebo', sans-serif;
}
