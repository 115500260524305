.table-column-fade {
    overflow: hidden;

    &::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 24px;
        height: 100%;
        width: 100px;
        background-image: linear-gradient(to right, rgba(255,255,255,0), white);
        pointer-events: none;
    }
}

.table-row:hover {
    .table-column-fade::after {
        background-image: none;
    }
}
