@use "sass:color";
@use "sass:map";
@use "../../node_modules/sass-rem" as *;
$font-size-base-px: 16px;

$theme-colors: (
    primary: #008cbd,
    light: #ffffff,
    dark: #4a6272,
    darker: #34414f,
    white-gray: #f5f5f5,
    lighter-gray: #e8e8e8,
    pale-gray: #e4e9f4,
    light-gray: #e0e0e0,
    medium-gray: #9aa5b5,
    dark-gray: #757575,
    light-blue: #f2f6fa,
    dark-blue: #222f3e,
    mirage: #1f2c39,
    success: #6bbd7d,
    danger: #ff5640
);

$energy-label-colors: (
    apppp: #009947,
    appp: #009947,
    app: #009947,
    ap: #009947,
    a: #009947,
    b: #35a036,
    c: #9fc61b,
    d: #fef804,
    e: #feb818,
    f: #ea6017,
    g: #e91521
);

@function theme-color($key: "primary") {
    @return map.get($theme-colors, $key);
}

$body-color: map.get($theme-colors, dark);

$border-color: map.get($theme-colors, pale-gray);

$btn-border-radius: 9999px;

$btn-primary-color: map.get($theme-colors, primary);
$btn-primary-bg: color.adjust(map.get($theme-colors, primary), $alpha: -0.1);

$border-color-light: rgba(255, 255, 255, 0.1);

$info-bar-width: rem(500px);
$info-bar-color: map.get($theme-colors, light);
$info-bar-bg: linear-gradient(to right, map.get($theme-colors, dark-blue), #2b3c4f);
$info-bar-content-padding: 0 rem(30px) rem(24px) rem(30px);

$info-bar-search-height: rem(80px);
$info-bar-search-bg: rgba(0, 0, 0, 0.1);
$info-bar-search-padding: 0 rem(15px);

$shadow-base: 0 rem(5px) rem(20px) 0 rgba(0, 0, 0, 0.1);
$border-radius-base: rem(3px);

$transition-easeInOutBack: cubic-bezier(0.68, -0.55, 0.265, 1.55);
