.export-button {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    height: 48px;
    padding: 0 .5rem;
    background: none;
    color: theme-color(dark-blue);
    border: 0;
    border-radius: 4px;
    outline: 0 !important;

    &:not(:disabled):active {
        opacity: .75;
    }

    &:not(:disabled):hover {
        background: var(--vwui-color-neutral-3);
    }

    &:disabled {
        opacity: .75;
    }

    &__icon,
    &__label {
        position: relative;
        display: flex;
        z-index: 2;
    }

    &__icon {
        align-items: center;
        justify-content: center;
        width: 1rem;
        height: 1rem;
        margin-left: auto;
        margin-right: .5rem;

        svg {
            width: 100%;
            height: 100%;
        }
    }
}
